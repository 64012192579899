import { useState } from '#imports'


export const useTheme = () => {

    const { ui } = useAppConfig().settings

    // Color modes (aka dark and light mode)
    // NOTE: colorMode uses <html :data-theme="colorMode" />
    const cm = useColorMode();
    const colorMode = computed(() => {
        if (!ui.useDarkMode || cm.unknown) return 'light'
        return cm.value
    })

    const isDark = computed(() => {
        return colorMode.value === 'dark'
    })

    const changeColorMode = () => {
        if (!ui.useDarkMode) return
        if (cm.preference === "system") {
            if (cm.value === "light" || cm.value === 'system') {
                cm.preference = "dark"
            }
            else if (cm.value === "dark") {
                cm.preference = "light";
            }
        } else if (cm.preference === "light") {
            cm.preference = "dark"
        } else {
            cm.preference = "light"
        }
    };


    const font = useState<'default' | 'dyslexic'>('font_type', () => 'default')
    const changeFont = () => {
        if (!ui.useDyslexicFont) return
        if (font.value === 'default') font.value = 'dyslexic'
        else font.value = 'default' 
    }
    const isDyslexic = computed(() => font.value === 'dyslexic' && ui.useDyslexicFont)


    return {
        colorMode,
        changeColorMode,
        isDark,
        font, 
        changeFont,
        isDyslexic,
    }
}